<template>
  <div class="box-ui-icon mt-3">
    <div class="fa-pull-left">
      <div class="col-icon" @click="selectExport">
        <em class="icon-view-section"></em>
        <span>{{$t('View selection')}}</span>
      </div>
      <div class="col-icon"  @click="clearExport">
        <em class="icon-delete"></em>
        <span>{{$t('Delete selection')}}</span>
      </div>
      <div class="col-icon" data-bs-toggle="modal" data-bs-target="#modal-config-column">
        <a>
          <em class="icon-organise-section"></em>
          {{$t('Show/Hide columns')}}
        </a>
      </div>
      <div class="col-icon" v-if="Agency === 'Overview'" @click="resetAgenciesColumns">
        <em class="icon-delete"></em>
        <span>{{$t('Reset default agencies\' columns')}}</span>
      </div>
    </div>
    <div class="fa-pull-right">
      <div class="icon-right" v-if="!Array.isArray(data.data)">
        <pagination :data="data.data" @pagination-change-page="getResults" :limit="3"></pagination>
      </div>
      <div class="icon-right" @click="fullScreen">
        <img src="@/assets/images/icon/ic-fullview.svg" alt="" />
      </div>
           
      <IconTopTableLegend :table_type="table_type" />

      <div v-if="canExport" class="icon-right main-overlay load-excel-overlay" @click="exportExcel" >
        <img src="@/assets/images/icon-download.svg" alt="" />
        <div class="overlay" v-if="load_excel">
          <div class="loadding_circle" ></div>
        </div>
      </div>

      <!-- <div class="icon-right main-overlay load-excel-overlay" @click="open_pdf" >
        <img src="@/assets/images/icon-download.svg" alt="" />
      </div>  -->
    </div>
    <div class="clearfix"></div> 
    <ModalConfigColumn  @request_result_agency="request_result_agency"/>
  </div>

  
 
</template>

<script>  
import ModalConfigColumn from '@/components/result/modal_config_column.vue'
import pagination from '@/components/elements/pagination.vue';
import IconTopTableLegend from '@/components/elements/icon_top_table_legend.vue'
// import html2pdf from 'html2pdf.js';

export default {
  
  name: "OverviewIconTopTable",
  components: {     
    ModalConfigColumn,
    pagination,
    IconTopTableLegend
    
  },
  props: {
    table_type:{
        type: String
    },
  },
  computed: {                
      data(){
          return this.$store.getters['result/data'];
      },
      Agency(){
        return this.$store.getters['result/Agency']
      },
      idSelectExport(){
        return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null
      },
      select_export_isSelect(){
        return this.$store.getters['result/select_export'].isSelect
      },
      filters(){
          return this.$store.getters['result/filters'];
      },
      load_excel(){
        return this.$store.getters['result/load_excel']
      },
      canExport(){            
          return this.$store.getters["users/export"];
      }
  },
  methods: {
      getResults(page = 1) {    
        this.$emit('request_result_agency', {page : page})
        document.querySelector('.content-table-results').scrollTop = 0
      },
      selectExport(){        
        this.$store.dispatch("result/select_export_isSelect", true)
        this.$emit('request_result_agency')
      },
      clearExport(){
        this.$store.dispatch("result/select_export_isSelect", false)
        this.$store.dispatch("result/select_export", [])
        this.$emit('request_result_agency')
      },
      exportExcel(){
        
        this.$emit('request_result_agency', {export_excel : true, order_by: this.data.sort_column.order_by, sort: this.data.sort_column.sort })
      },
      fullScreen(){
        const routeData = this.$router.resolve({query: Object.assign({}, this.$route.query, { zoom : true }) });
        window.open(routeData.href, '_blank');        
      },
      request_result_agency(param = {}){
          this.$emit('request_result_agency', param);
      }, 
      resetAgenciesColumns() {
        let objColumn = {}
        this.data.column.forEach((val) => {
          if(!val.parent || !val.parent.includes('overview'))
              objColumn[val.code] = true;                    
        });
        this.$store.dispatch("result/saveColumn", { column : objColumn, agency : this.Agency, reset_column: this.data.reset_column, reset: true});
      },
      // open_pdf(){
      //       let element = document.getElementById("content-table-results")
      //       let opt = {
      //           margin: 0,
      //           filename: this.data.agency+'.pdf',
      //           image: { type: 'png'},
      //           html2canvas: { dpi: 300, letterRendering: true, imageTimeout : 0, scale:1, backgroundColor : null},
      //           jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape'}
      //       }

      //       // document.getElementById('header').style.position = "static"
      //       // document.getElementById('detail').classList.add('mx-0')
            
      //       html2pdf().set(opt).from(element).save().catch(function(err){
      //           console.error(err)
      //       })
            
      //       this.$forceUpdate()
      // },
  }, 
};
</script>

<style scoped>
</style>