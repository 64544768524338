<template>
    <div>
        <div :style="'overflow: hidden; height:'+height+';'" >
            <div ref="readmore_overview">
                <template v-for="(link_data, link_key) in item_data_readmore.filter(item => item.agency_en == column_lv3.agency[0])" :key="link_key"> 
                    <div v-if="column_lv3['column_reimbursement'].includes('Regulatory')&&(link_data.decision_mapping||link_data.decision_date_num||link_data.decision_date)" @click="show = link_data.drug_id; item_data = link_data" style="height:70px;" data-bs-toggle="modal" data-bs-target="#modal-hta-status" v-html="overview_regulatory_status(link_data)" ></div> 
                    <div v-else-if="column_lv3['column_reimbursement'].includes('HTA')&&(link_data.hta_decision||link_data.hta_process_date)" class="regular_status hta" @click="show = link_data.drug_id; item_data = link_data" style="height:70px;" data-bs-toggle="modal" data-bs-target="#modal-hta-status" v-html="overview_hta_process_status(link_data)" ></div> 
                    <div v-else-if="column_lv3['column_reimbursement'].includes('Reimbursement')&&(link_data.reimbursement_decision||link_data.rembursement_process_date)" class="regular_status rembursement" @click="show = link_data.drug_id; item_data = link_data" style="height:70px;" data-bs-toggle="modal" data-bs-target="#modal-hta-status" v-html="overview_rembursement_status(link_data)" ></div> 
                    <div v-else-if="link_data['agency_type'].match(/\//)" style="height:70px;"></div>
                </template> 
            </div>
        </div> 
        <div class="overview text-center" v-if="readmore_height > 70">
            <a :class="'readmore_click '+syn_class_button" v-if="height != 'auto'" @click="height = 'auto';syn_readmore(syn_class_button);">
                [...]
            </a>
            <a :class="'readmore_click '+syn_class_button" v-else @click="height = max_height ;syn_readmore(syn_class_button);">
                [ ^ ]
            </a>
        </div>
    </div>
</template>
<script>
import { overview_hta_process_status,overview_rembursement_status,overview_regulatory_status } from '@/utils';

export default {
    name: "ReadmoreOverview",
    data(){
        return {
            max_height : "70px",
            height : "70px",            
            readmore_height : 0,
            show : 0,
            item_data : {},
        }
    }, 
    props: {
        item_data_readmore:{
            type: Array
        },
        column_lv3:{
            type: Object
        },
        syn_class_button:{
            type: String
        }
    },
    methods: {
        overview_hta_process_status,  
        overview_rembursement_status,
        overview_regulatory_status, 
        syn_readmore(class_sync){
            var checkboxes = document.getElementsByClassName(class_sync);
            for (var i=0; i<checkboxes.length; i++) {
                checkboxes[i].click();
            }
        }, 
        check_height(){
            if(this.$refs.readmore_overview){
                this.readmore_height = this.$refs.readmore_overview.offsetHeight
            }
        }
    },
    mounted() {     
        this.check_height();
    },
    updated() {
        this.check_height();
    },
    watch: {
        show: function() { 
            this.$emit("dataOverview", this.item_data);
            this.show = 0
        }
    }
}
</script>

<style>

.readmore_click{
    color:#ff6300;  
    cursor: pointer;
}
</style>
<style scope>
center.overview {
    margin-top: -20px;
}
</style>