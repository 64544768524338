<template>
    <colgroup> 
        <col style="width: 30px;" />
        <col style="width: 35px;" />
        
        <template v-for="(item) in data.column" >


            <template v-if="item.parent === '0'">
                <template v-if="item.Status">
                    <template v-for="(item2) in children_column(item.code)" >
                            <template v-for="(item3) in children_column(item2.code)" :key="item3.code" >
                                <template v-if="isColumnHide(item3.code) && (type === 'decision' || (type === 'delay' && item3.text === 'Reimbursement'))">
                                    <col :style="'width: '+getColumnWidth(item3)+'px;'" :code="item3.code"/>
                                </template>
                            </template>
                    </template>
                </template>
            </template>

            <template v-else>
                <template v-if="item.Status && !item.parent">
                    <col :style="'width: '+item.width+'px;'" :key="item.code" :code="item.code"/>
                </template> 
            </template>            

        </template>
         
    </colgroup>
</template>
    
<script>
export default {
    name: "OverviewColumnColGroup",
    props: {
        type:{
            type: String
        }
    },
    computed: {       
        data(){
            return this.$store.getters['result/data'];
        },
        children_column(){
            return code => this.data.default_column.filter(item => item.parent == code) 
        },
    },
    methods: {
        isColumnHide(code) {
            const matchedColumn = this.data.column.find(c => c.code === code)
            if(matchedColumn)
                return matchedColumn.Status 

            return true
        },

        /**
         * Return parent's column width if there is only one active children (for specific case 'Transparency Committee')
         */
        getColumnWidth(column) {
            const children = this.data.column.find(c => c.parent === column.parent && c.code !== column.code && !c.Status)
            if(children)
                return this.data.default_column.find(c => c.code === column.parent).width
            
            return column.width
        }
    },
}
</script>

<style>

</style>