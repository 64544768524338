<template>
    <div>
        <div v-if="delay" class="delay" :style="'background-color: '+redPalette(delay)">
            <p>{{ (isNaN(delay)) ? $t('legend.'+delay) : delay }}</p>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { redPalette } from '@/utils'

export default {
    name: "DelayOverview",
    data(){
        return {
            delay: null, 
            authorized_decisions: ['Yes', 'Partial', 'Year one']
        }
    }, 
    props: {
        item_data:{
            type: Array
        },
        column_lv3:{
            type: Object
        },
    },
    methods: {
        /**
         * Get oldest reimbursement date by drug_centers for reimbursement column only and compare delay with MA date
         * Return ongoing if no reimbursement date and decision = ongoing
         */
        getDelay() {
            let linked_drug_centers = this.item_data.filter(item => item.agency_en == this.column_lv3.agency[0])
            let oldDate = null
            let ma_date = null
            linked_drug_centers.forEach(element => {
                if(this.column_lv3['column_reimbursement'].includes('Reimbursement') && this.authorized_decisions.includes(element.reimbursement_decision) && element.rembursement_process_date && element.ma_date && (!oldDate || element.rembursement_process_date < oldDate || oldDate === 'Ongoing')) {
                    oldDate = element.rembursement_process_date
                    ma_date = element.ma_date
                } else if(element.reimbursement_decision === 'Ongoing'){
                    oldDate = element.reimbursement_decision
                } else if((!element.rembursement_process_date || !element.ma_date) && this.authorized_decisions.includes(element.reimbursement_decision)) {
                    oldDate = 'not-available'
                }
            });

            if(oldDate && ma_date && (oldDate < ma_date || oldDate === ma_date))
                return '0'

            if(['Ongoing','not-available'].includes(oldDate))
                return oldDate

            if(!oldDate || !ma_date)
                return;

            const date1 = moment(ma_date);
            const date2 = moment(oldDate)
            return date2.diff(date1, 'days')
        },
        redPalette
    },
    mounted() {
        this.delay = this.getDelay()
    },
    watch: {
    }
}
</script>

<style>

.readmore_click{
    color:#ff6300;  
    cursor: pointer;
}
</style>
<style scope>
center.overview {
    margin-top: -20px;
}
</style>