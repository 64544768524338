<template>
    
    <thead>        
        <tr>
            <th data-html2canvas-ignore="true">
                <label class="table-check">
                    <input type="checkbox" v-model="select_export_checkAll"  id="checkbox" >
                    <!-- <input type="checkbox"  id="checkbox" > -->
                    <span class="checkbox-table"></span>
                </label>
            </th>
            <th  data-html2canvas-ignore="true" style="white-space: nowrap;">{{$t('Card')}}</th>
            
                <template v-for="(item) in data.column" >

                    <template v-if="item.parent === '0'">
                        <template v-if="item.Status">
                        <template v-if="objColspan[item.code] && (type === 'decision' || (type === 'delay' && reimbursement_countries.includes(item.code)))">
                            <th class="sub-table" style="padding: 0px 0px;box-shadow: inset 0 2px 0 #ddd, inset 0 -2px 0 #ddd;" :colspan="type === 'decision' ? objColspan[item.code]['count'] : 1" :key="item.code" :code="item.code">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <td :code="item.code" :colspan="type === 'decision' ? objColspan[item.code]['count'] : 1" style="border-left: 1px solid #dee2e6;border-right: 1px solid #dee2e6;">{{ uppercase(item.text) }}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <template v-for="(item2) in children_column(item.code)" >
                                                <template v-if="item.Status && item2.Status && (type === 'decision' || (type === 'delay' && reimbursement_agencies.includes(item2.code)))">
                                                    <th class="sub-table" style="padding: 0px 0px;border-left: 1px solid #dee2e6;border-right: 1px solid #dee2e6;" :colspan="type === 'decision' ? objColspan[item.code][item2.code]['count'] : 1" :key="item2.code" :code="item2.code" >
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <td v-if="$i18n.locale !== 'fr'" :code="item.code" :colspan="objColspan[item.code][item2.code]['count']" style="">{{item2.agency_en}}</td>
                                                                    <td v-else :code="item.code" :colspan="objColspan[item.code][item2.code]['count']" style="">{{item2.agency_fr}}</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <template v-for="(item3) in children_column(item2.code)" >
                                                                        <template v-if="item3.Status && (type === 'decision' || (type === 'delay' && item3.text === 'Reimbursement'))">
                                                                            <template v-if="item3.column_reimbursement">
                                                                                <th :code="item3.code" :key="item3.code" :style="'padding: 8px 0px;width:'+getColumnWidth(item3)+'px; vertical-align: middle;border-right: 1px solid #dee2e6;'">
                                                                                    <div style="position:relative">
                                                                                        <span @click="orderBy(item3)" style="cursor:pointer"><em v-if="isOrdered(item3)" style="font-size: 13px;position: relative;top: 1px;right: 3px;" class="fas fa-caret-down"></em>{{$t(item3.text)}}</span>
                                                                                        <span v-if="type === 'decision'" style="cursor:pointer;position: absolute;right: 2px;top: -7px;" @click="removeColumn(item3)"> X</span>
                                                                                    </div>
                                                                                </th>
                                                                            </template>
                                                                            <template v-else>
                                                                                <th :code="item3.code" :key="item3.code" :style="'padding: 8px 0px;width:'+getColumnWidth(item3)+'px; vertical-align: middle;'">
                                                                                    <span @click="orderBy(item3)">{{$t(item3.text)}}</span>
                                                                                </th>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </tr>
                                                            </tbody>
                                                        </table>    
                                                    </th> 
                                                </template>

                                            </template>
                                        </tr>
                                    </tbody>
                                </table>    
                            </th> 
                        </template>
                        </template>
                    </template>


                    <template v-else>
                        <template v-if="item.Status && (item.parent === '0' || !item.parent)">
                            <th :key="item.code" :code="item.code">
                                <span>{{item.text}}</span>
                            </th> 
                        </template> 
                    </template>            

                </template> 

        </tr>
    </thead>
</template>

<script>
import { uppercase } from '@/utils/helper'

export default {
    name: "OverviewColumnThead",
    props: {
        type:{
            type: String
        },
        reimbursement_countries:{
            type: Array
        },
        reimbursement_agencies: {
            type: Array
        }
    },
    data() { 
        return {          
            objColspan : {},
            tempdataColumn: {}
        }
    },
    methods:{
        uppercase,
        setObjColspan(){
            let dataColspan = this.data
            this.tempdataColumn = dataColspan.column
            let objColspan = {};
            for(const item of dataColspan.column){
                if(item.parent === '0'){
                    objColspan[item.code] = {};
                    for(const item2 of this.children_column(item.code)){
                        if(item2.parent){
                            
                            objColspan[item.code][item2.code] = {};
                            for(const item3 of this.children_column(item2.code)){

                                objColspan[item.code][item2.code][item3.code] = {};

                                if(item3.text && item3.Status){
                                        
                                    if(objColspan[item.code]['count']){
                                        objColspan[item.code]['count'] += 1;
                                    }else{
                                        objColspan[item.code]['count'] = 1; 
                                    }

                                    if(objColspan[item.code][item2.code]['count']){
                                        objColspan[item.code][item2.code]['count'] += 1;
                                    }else{
                                        objColspan[item.code][item2.code]['count'] = 1; 
                                    }

                                    if(objColspan[item.code][item2.code][item3.code]['count']){
                                        objColspan[item.code][item2.code][item3.code]['count'] += 1;
                                    }else{
                                        objColspan[item.code][item2.code][item3.code]['count'] = 1; 
                                    }

                                }
                            }
                        }
                    }
                }    
            } 
            this.objColspan = objColspan
        },

        /**
         * Handle click for agency's column removing. 
         */
        removeColumn(column) {
            let columnsToHide = this.recursiveRemove(column, [])
            let objColumn = {};
            this.data.column.forEach((val) => {
                objColumn[val.code] = val.Status;                    
            });

            columnsToHide.forEach(c => {
                objColumn[c.code] = c.Status;
            })
            this.$store.dispatch("result/saveColumn", { column : objColumn, agency : this.Agency, reset_column: this.data.default_column});
        },

        /**
         * Get agency's column recursively to remove 
         */
        recursiveRemove(column, columnsToHide) {
            if(column.parent !== "0"){
                let activeChildren = this.data.column.filter(item => item.parent == column.parent && item.code !== column.code) //get already column filter once
                if(activeChildren.length > 0)
                    activeChildren = activeChildren.filter(item => item.Status) //if exists, get displayed ones
                else
                    activeChildren = this.data.default_column.filter(item => item.parent == column.parent && item.code !== column.code) //if not get default one

                if(!columnsToHide.find(c => c.code === column.parent)){
                    columnsToHide.push({code: column.parent, Status: (activeChildren.length > 0 || (activeChildren.length === 0 && columnsToHide.find(c => c.code === column.code && c.Status))) ? true : false})
                    columnsToHide.concat(this.recursiveRemove(this.data.default_column.find(item => item.code === column.parent), columnsToHide))
                }
            }

            if(!columnsToHide.find(c => c.code === column.code))
                columnsToHide.push({code: column.code, Status: false})

            return columnsToHide
        },

        /**
         * Handle click on column for ordering
         */
        orderBy(column) {
            let paramObj = {...this.$route.params}
            paramObj['agency'] = this.Agency
            paramObj['order_by'] = 'agency_type='+column.agency_model[0] + '|' + column.column_reimbursement[0]
            this.$store.dispatch(`result/result_agency`, paramObj)
        },

        /**
         * Get ordering status for a specific column
         */
        isOrdered(column) {
            if(this.data.sort_column.order_by.includes('agency_type')){
                let sortData = this.data.sort_column.order_by.split('=')[1].split('|')
                const isColumnFound = this.data.default_column.find(c => column.code === c.code && c.column_reimbursement.includes(sortData[1]) && c.agency_model.includes(sortData[0]))
                if(isColumnFound)
                    return true
            }
            
            return false
        },
        /**
         * Return parent's column width if there is only one active children (for specific case 'Transparency Committee')
         */
        getColumnWidth(column) {
            const children = this.data.column.find(c => c.parent === column.parent && c.code !== column.code && !c.Status)
            if(children)
                return this.data.default_column.find(c => c.code === column.parent).width
            
            return column.width
        }
    },    
    watch : {
        data: function (data) {
            if(data && data.column){
                this.setObjColspan()
            }
        },
    },
    mounted() {
           this.setObjColspan()
    },
    computed: {       
        data(){
            return this.$store.getters['result/data'];
        },
        Agency(){
            return this.$store.getters['result/Agency']
        },
        children_column(){
            return code =>  this.data.default_column
                            .filter(item => item.parent == code)
                            .map((item) => {
                                return (this.data.column.find(i => i.code === item.code)) ? this.data.column.find(i => i.code === item.code) : item
                            }) 
        },
        select_export_checkAll: {
            set(val){ this.$store.dispatch("result/select_export_checkAll", val) },
            get(){ return this.$store.getters['result/select_export'] && this.$store.getters['result/select_export'].checkAll ? this.$store.getters['result/select_export'].checkAll : null }
        }
    },
    updated() {
        if(this.data.column !== this.tempdataColumn)
        {
            this.setObjColspan()
        }
    },
};
</script>

<style>
.table thead th {
    border-bottom: none !important;
}
</style>