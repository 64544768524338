<template>
    <div class="icon-right open-menu" >
        <img src="@/assets/images/icon/legend.svg" @click="openLegend = !openLegend" alt="">
        <div :class="'ele-legend open-bar-legend' + (openLegend ? ' open-bar' : '')  + ' ' + table_type">
          <template v-if="table_type === 'decision' || !table_type">
            <div>
              <span class="checklist rembursement-green"></span>
              <span>{{$t('legend.Positive')}}</span>
            </div>
            <div>
              <span class="checklist rembursement-orange"></span>
              <span>{{$t('legend.Partial')}}</span>
            </div>
            <div>
              <span class="checklist rembursement-red"></span>
              <span>{{$t('legend.Negative')}}</span>
            </div>       
            <div>
              <span class="checklist-reload"></span>
              <span>{{$t('legend.Ongoing')}}</span>
            </div>
            <div>
            <span class="checklist non-submission"></span>
              <span>{{$t('legend.Non-submission')}}</span>
            </div>
            <div>
              <span class="checklist withdrawn"></span>
              <span>{{$t('legend.Withdrawn')}}</span>
            </div> 
            <div>
            <span class="checklist suspended"></span>
              <span>{{$t('legend.Suspended')}}</span>
            </div> 
          </template>
          <template v-else>
              <div>
                <span>{{$t('legend.Low')}}</span>
                <div style="background: rgb(247,106,106);background: linear-gradient(90deg, rgba(247,106,106,1) 0%, rgba(224,24,24,1) 50%, rgba(114,11,11,1) 100%);height: 40px;width:250px;margin: 0 5px;display:inline-block"></div>
                <span>{{$t('legend.High')}}</span>
              </div>
              <div>
                <div style="background: #46AD6D;height: 40px;width:40px;margin: 0 5px;display:inline-block"></div>
                <span>{{$t('legend.not-available')}}</span>
              </div>
              <div>
                <div style="background: #BBC2D0;height: 40px;width:40px;margin: 0 5px;display:inline-block"></div>
                <span>{{$t('legend.Ongoing')}}</span>
              </div>
          </template>
        </div>
    </div>
</template>

<script>
export default {
    name:"IconTopTableLegend",
    props: {
      table_type:{
          type: String
      },
    },    
    data(){
        return {
           openLegend: false
        }
    }
}
</script>
<style scoped>
  .open-bar-legend.open-bar{
    z-index:3;
  }
</style>