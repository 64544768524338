<template>
    <div>
        <div class="body-w100">
            <div class="box-detail">
                <div v-if="data && data.data.data.length > 0">
                    <div class="data_type_selector_container d-flex">
                        <select v-model="table_type">
                            <option value="decision">{{$t('Decisions')}}</option>
                            <option value="delay">{{$t('Delays')}}</option>
                        </select>
                        <div v-if="table_type === 'delay'" class="box-check p-2 form-input">
                            <label>
                                <input type="checkbox" name="exclude_no_delay" value="true" v-model="no_delay">
                                <span class="check-recherche"></span>
                                <div class="txt-check bold">{{ $t('Exclude indication without any delay') }}</div>
                            </label>
                        </div>
                    </div>
                    
                    <OverviewIconTopTable @request_result_agency="request_result_agency" :table_type="table_type" />
                    <div class="content-table-results mt-2" >
                        <table class="table table-fixed table-stripes" id="content-table-results">                    
                            <OverviewColumnColGroup v-if="data" :type="table_type"/> 
                            <OverviewColumnThead v-if="data" :type="table_type" :reimbursement_agencies="agenciesWithReimbursementAgency"  :reimbursement_countries="countriesWithReimbursementAgency" />                   
                            <tbody> 
                                <template v-if="data && mostRecentInfo && mostRecentInfo.length > 0">
                                    <tr v-for="(item, key) in data.data.data" :key="key">                
                                        <template v-if="hasDecisionInLinkedDrugCenters(item['link_indication']) && (table_type === 'decision' || (table_type === 'delay' && ((no_delay && hasDelay(item['link_indication'])) || !no_delay )))">
                                        
                                        <td class="middle" data-html2canvas-ignore="true">
                                            <label class="table-check">
                                                <input type="checkbox" v-model="select_export" :value="mostRecentInfo[key].id">
                                                <span class="checkbox-table"></span>
                                            </label>
                                        </td>
                                        <td  data-html2canvas-ignore="true">
                                            <div class="icon-fiche">
                                                <template v-if="translate[mostRecentInfo[key].agency_model]">
                                                    <span v-html="icon_link(mostRecentInfo[key].drug_id,translate[mostRecentInfo[key].agency_model]['link'])"></span>
                                                </template>
                                            </div>
                                            <div v-if="isAdmin">
                                                <a :href="modifyPage(mostRecentInfo[key].agency_model.toLowerCase(), mostRecentInfo[key].drug_id)" target="_blank" rel="noopener"> 
                                                    <em class="icon-edit"></em>
                                                </a>
                                            </div>
                                        </td>
                                        <template v-for="(column, key_column) in data.column" :key_column="key_column" :key="column.code" >

                                            <td v-if="column.Status && column.code == 'overview_001'" :code="column.code" style="padding-top: 10px;">
                                                <Readmore @click="essais_clinique_popup(mostRecentInfo[key].drug_id, mostRecentInfo[key].agency_model)" :longText="nct_number(mostRecentInfo[key].nct_numbers)" :textAlign="'center'"/>
                                            </td>
                                            <td  v-if="column.Status && column.code == 'overview_009'" :code="column.code" >
                                                {{mostRecentInfo[key].category}}
                                            </td>
                                            <td  v-if="column.Status && column.code == 'overview_008'" :code="column.code" >
                                                {{mostRecentInfo[key].dci_new}}
                                            </td>
                                            <td  v-if="column.Status && column.code == 'overview_004'" :code="column.code" >
                                                <Readmore v-if="mostRecentInfo[key]['indexation_'+$i18n.locale]" :longText="array(mostRecentInfo[key]['indexation_'+$i18n.locale])" :textAlign="'center'"/>
                                            </td>
                                            <td  v-if="column.Status && column.code == 'overview_005'" :code="column.code" class="middle" style="text-align: left">
                                                <Readmore v-if="mostRecentInfo[key]['indication_'+$i18n.locale]" :longText="mostRecentInfo[key]['indication_'+$i18n.locale]" />
                                            </td>
                                            <td  v-if="column.Status && column.code == 'overview_006'" :code="column.code" >
                                                <span v-if="$i18n.locale !== 'fr'" v-html="array(mostRecentInfo[key].classification_en)"  ></span>
                                                <span v-else v-html="array(mostRecentInfo[key].classification_fr)"></span>
                                            </td>
                                            <td  v-if="column.Status && column.code == 'overview_007'" :code="column.code" >
                                                <span v-html="treatment_line(mostRecentInfo[key].treatment_line)"></span>
                                            </td>
                                            <td  v-if="column.Status && column.code == 'overview_010'" :code="column.code" :style="table_type === 'delay' ? regulatory_border_right.td : ''">
                                                {{item.link_indication ? item.link_indication.indication_id : ''}}
                                            </td>
                                            <template v-for="(column_lv3, key_column) in data.dynamic_column_lv3[column.model]" :key_column="key_column" :key="column_lv3.code">
                                                <td v-if="column.Status && column.code == column_lv3.code_lv1 && isColumnHide(column_lv3.code) && (table_type === 'decision' || (table_type === 'delay' && column_lv3.text === 'Reimbursement'))" :code="column_lv3.code" class="middle" :class="table_type === 'delay' ? 'padding0' : ''" :style="column_lv3.border_left? column_lv3.border_right? column_lv3.border_left && column_lv3.border_right? regulatory_border_left.td+regulatory_border_right.td :'': regulatory_border_right.td : regulatory_border_left.td">
                                                    <template v-if="item['link_indication'] && item['link_indication']['linked_drug_centers'] && item['link_indication']['linked_drug_centers'].length > 0">
                                                        <ReadmoreOverview v-if="table_type === 'decision'" :syn_class_button="item.drug_id+'_'+column_lv3.model" :column_lv3="column_lv3" :item_data_readmore="item['link_indication']['linked_drug_centers']" @dataOverview="dataOverview"/>
                                                        <template v-else>
                                                            <div>
                                                            <DelayOverview :column_lv3="column_lv3" :item_data="item['link_indication']['linked_drug_centers']" /> 
                                                            </div>
                                                        </template> 
                                                    </template>
                                                </td>
                                            </template>
                                        </template>
                                    </template>
                                </tr>
                                </template>
                            </tbody>

                        </table>
                    </div> 
                </div>

                <div v-else class="wrapper-box-hta">
                    <h1 class="fa-pull-left mr-4 mt-1">{{$t('No Data')}}</h1>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
        <OverviewModalHTAstatus :show="show" :item_data="item_data"/>
        <ModalEssaisClinique :id="drug_id" :agency="agency" />
    </div>
</template>

<script>
import OverviewColumnColGroup from '@/components/result/overview_column_colgroup.vue';
import OverviewColumnThead from '@/components/result/overview_column_thead.vue';
import OverviewIconTopTable from '@/components/result/overview_icon_top_table.vue';
import OverviewModalHTAstatus from '@/components/result/overview_modal_hta_status.vue'
import Readmore from '@/components/elements/readmore.vue';
import ReadmoreOverview from '@/components/elements/readmore_overview.vue';
import DelayOverview from '@/components/elements/delay_overview.vue'
import { isAdmin, overview_hta_process_status,overview_rembursement_status,overview_regulatory_status, 
        icon_link, nct_number, array, treatment_line, modifyPage } from '@/utils';
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
export default {
  name: "TabOverview",
  components: {
        OverviewColumnColGroup,
        OverviewColumnThead,
        OverviewIconTopTable,
        OverviewModalHTAstatus,
        Readmore,
        ReadmoreOverview,
        DelayOverview,
        ModalEssaisClinique
    },
    data() {
      return{
            show : 0,
            drug_id : 0,
            agency : '',
            item_data : {},
            agency_drug_id : 0,
            agency_model : '',
            agency_type : '',
            regulatory_border_left:{
                td : "border-left: 1px solid #dee2e6;"
            },
            regulatory_border_right:{
                td : "border-right: 1px solid #dee2e6;"
            },
            translate:{
                "Ema":{icon:"inter",agency:'EMA',link:'ema'},
                "Fda":{icon:"usa",agency:'FDA',link:'fda'},
                "Transparence":{icon:"fr",agency:'TC',link:'trs'},
                "Ceesp":{icon:"fr",agency:'CEESP',link:'ceesp'},
                "Rtu":{icon:"fr",agency:'RTU',link:'rtu'},
                "Nice":{icon:"uk",agency:'NICE',link:'nice'},
                "Cdf":{icon:"uk",agency:'CDF',link:'cdf'},
                "Gba":{icon:"g",agency:'G-BA',link:'gba'},
                "Iqwig":{icon:"g",agency:'IQWIG',link:'iqwig'},
                "Spain":{icon:"es",agency:'SPAIN',link:'spain'},
                "Italy":{icon:"it",agency:'ITALY',link:'italy'},
                "Tlv":{icon:"sw",agency:'TLV',link:'tlv'},
                "Ntcouncil":{icon:"sw",agency:'NT Council',link:'ntcouncil'},
                "Ncpe":{icon:"ie",agency:'NCPE',link:'ncpe'},
                "Inami":{icon:"be",agency:'INAMI',link:'inami'},
                "Smc":{icon:"sco",agency:'SMC',link:'smc'},
                "Zinl":{icon:"nd",agency:'ZiNL',link:'zinl'},
                "Awmsg":{icon:"wl",agency:'AWMSG',link:'awmsg'},
                "Aotmit":{icon:"wl",agency:'AOTMIT',link:'aotmit'},
                "Usa":{icon:"usa",agency:'ICER',link:'usa'},
                "Cadth":{icon:"ca",agency:'CADTH',link:'cadth'},
                "Perc":{icon:"ca",agency:'pCODR',link:'perc'},
                "HealthCanada":{icon:"ca",agency:'Healt Canada',link:'health_canada'},
                "Colombia":{icon:"co",agency:'IETS',link:'iets'},
                "Peru":{icon:"pe",agency:'PERU',link:'peru'},
                "Bresil":{icon:"br",agency:'CONITEC',link:'bresil'},
                "Pbac":{icon:"au",agency:'PBAC',link:'pbac'},
                "Ptac":{icon:"zn",agency:'PTAC',link:'ptac'},
                "Pmda":{icon:"jp",agency:'PMDA',link:'pmda'},
                "Hira":{icon:"kr",agency:'HIRA',link:'hira'},
                "Singapore":{icon:"sg",agency:'HIRA',link:'ace'},
                "Nihta":{icon:"tw",agency:'HIRA',link:'nihta'},
                "Inesss":{icon:"ca",agency:'CANADA',link:'inesss'},
            },
            exist_column : [],
            is_admin : false,
            table_type: 'decision',
            agenciesWithReimbursementAgency: [],
            countriesWithReimbursementAgency: [],
            no_delay: false
        }
         
    },
    props: {
    },
    mounted(){  
        this.$store.dispatch("result/setTranslateRembursemet", this.translate)
    }, 
    updated(){
        console.log('tab_overview.vue updated')
    },
    computed: { 
        isAdmin, 
        user(){
            return this.$store.getters['auth/user']
        },         
        data(){
            return this.$store.getters['result/data'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        },   
        exist_column_overview(){
            return this.$store.getters['result/exist_column_overview'];
        },
        mostRecentInfo() { // parse data to display informations depending on first linked agency
            const data = this.$store.getters['result/data']
            let mostRecentInfo = [];
            const agenciesColumns = data.column.filter((c) => {
                return c.parent === "0"
            })
            data.data.data.forEach((element, index) => {
                mostRecentInfo[index] = null
                if(agenciesColumns.length > 0 && element.link_indication.linked_drug_centers.length > 0){
                    agenciesColumns.forEach((aC) => {
                        element.link_indication.linked_drug_centers.forEach((dc) => {
                            if(aC.agency_model.includes(dc.agency_model) && !mostRecentInfo[index])
                                mostRecentInfo[index] = dc
                        })
                    });

                    if(!mostRecentInfo[index])
                        mostRecentInfo[index] = element
                } else
                    mostRecentInfo[index] = element
            });

            return mostRecentInfo
        }
    },
    methods: {
        overview_hta_process_status,  
        overview_rembursement_status,
        overview_regulatory_status,
        modifyPage,
        icon_link,
        nct_number,
        array,
        treatment_line,
        mergeArrayObjects(arr1,arr2){
            for(let i in arr2){
                arr1[i] = arr2[i]
            }
            return arr1
        },
        essais_clinique_popup(id, agency){
            this.drug_id = id,
            this.agency = agency
        },
        dataOverview(param){
            this.show = param.drug_id
            this.item_data = param
        },
        request_result_agency(param = {}){
            this.$emit('request_result_agency', param);
        },
        isColumnHide(code) {
            const matchedColumn = this.data.column.find(c => c.code === code)
            if(matchedColumn)
                return matchedColumn.Status 

            return true
        },
        get_countries_with_reimbursement_agencies() {
            this.agenciesWithReimbursementAgency = this.data.default_column.filter(item => item.text === 'Reimbursement').map(item2 => item2.parent)
            this.countriesWithReimbursementAgency = this.data.default_column.filter(i => this.agenciesWithReimbursementAgency.includes(i.code) ).map(i2 => i2.parent)
        },
        /**
         * Determine if indication has at least one decision (between the linked agencies)
         */
        hasDelay(drug_centers) {
            let hasDelay = false
            if(drug_centers['linked_drug_centers'] && drug_centers['linked_drug_centers'].length > 0) {
                drug_centers['linked_drug_centers'].forEach(item => {
                    if(item.agency_type.includes('Reimbursement') && ['Yes', 'Partial'].includes(item.reimbursement_decision))
                        hasDelay = true
                })
            }
            return hasDelay;
        },

        /**
         * Determine if indication has at least one agency with a decision
         */
        hasDecisionInLinkedDrugCenters(drug_centers) {
            let hasDecision = false
            if(drug_centers['linked_drug_centers'] && drug_centers['linked_drug_centers'].length > 0) {
                drug_centers['linked_drug_centers'].forEach(dc => {
                    if((dc.agency_type === 'Regulatory' && dc.decision_mapping) ||
                    (dc.agency_type === 'HTA' && dc.hta_decision) ||
                    (dc.agency_type === 'Reimbursement' && dc.reimbursement_decision) ||
                    (dc.agency_type === 'HTA/Reimbursement' && (dc.hta_decision || dc.reimbursement_decision) ))
                        hasDecision = true
                })
            }

            return hasDecision
        }
    },
    watch: {
        data: function() { 
            if(this.data && this.data.default_column){
                for(let key in this.data.default_column){
                    this.exist_column.push(this.data.default_column[key].code);
                } 
            }
        },
        exist_column: function() { 
             this.$store.dispatch("result/setExistColumnOverview", this.exist_column)
        },
        table_type: function() {
            if(this.table_type === 'delay')
                this.get_countries_with_reimbursement_agencies()
        }
    }
}
</script>

<style scoped>
.regular_status{
    height: 80px;
}
.table tr td{
    vertical-align: top !important;
}
@media only screen and (max-width: 862px){
    .content-table-results{
        max-height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 61vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>
