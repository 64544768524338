<template v-if="item_data">
    <teleport to="body">
    <div class="modal fade" id="modal-hta-status" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-hta">
            <div class="modal-content modal-content-hta">    
                <div class="modal-body modal-dialog-hta">
                    
                    <template  v-if="this.data_reimbursement">
                        <div class="conclusion_head">
                            <img v-if="this.data_reimbursement.agency_model" :alt="this.data_reimbursement.agency_model" :src="require('@/assets/images/flag/new/'+this.data_reimbursement.icon_flags+'.svg')" width="20" height="20" />
                            <strong><span style="vertical-align: middle; padding: 7px;">{{this.data_reimbursement['acronym_'+$i18n.locale]}}</span></strong>
                            <span style="vertical-align: middle; padding-right: 5px;">{{item_data.name}}</span>
                            <span style="vertical-align: middle;" v-html="icon_link(item_data.drug_id, link_detail_url(item_data.agency_model), class_='size20-cover')"></span>
                        </div>
                        <div class="custom-modal-header"> 
                            <a class="icon-close-popup close-popup" data-bs-dismiss="modal" aria-label="Close" style="top: 10px; right: 10px;"></a>
                        </div>

                        <template v-if="this.data_reimbursement.agency_model === 'Ema' || this.data_reimbursement.agency_model === 'Fda'|| this.data_reimbursement.agency_model === 'HealthCanada'">
                            <!-- <div style="margin-top: 15px;">
                                <div class="pull-right">
                                    <p v-if="hta_date" style="margin-bottom:0"><strong> Decision Date : </strong> <span v-html="hta_date"></span></p>
                                    <p v-if="decision" style="margin-bottom:0"><strong>Decision :</strong> <span v-html="decision"></span></p>
                                    <p v-if="rembursement_process" style="margin-bottom:0"><strong>Reimbursement status :</strong> <span v-html="rembursement_process"></span> </p>
                                </div>
                            </div> -->


                            <div  v-if="this.data_reimbursement.agency_model === 'Fda'" style="margin-top: 15px;">
                                <div class="pull-right">
                                    <p v-if="decision_date" style="margin-bottom:0"><strong>{{ $t("overview.Decision date") }} :</strong> <span v-html="decision_date"></span> </p>
                                    <p v-if="decision" style="margin-bottom:0"><strong>{{ $t("overview.Decision") }} :</strong> <span v-html="decision"></span> </p>
                                </div>
                            </div>

                            <div style="margin-top: 15px;"></div>
                            <template v-if="this.data_reimbursement['classification_'+($i18n.locale==='fr' ? 'fr' : 'en')]">
                                <p><strong>{{ $t("Classification")}} : </strong>
                                    {{this.data_reimbursement['classification_'+($i18n.locale==='fr' ? 'fr' : 'en')].join(", ")}}
                                </p>
                            </template>
                            <template v-if="this.data_reimbursement.agency_model === 'Ema' && this.data_reimbursement['type_of_ma_'+($i18n.locale==='fr' ? 'fr' : 'en')]">
                                <p><strong>{{ $t("Type of MA")}} : </strong>
                                    {{this.data_reimbursement['type_of_ma_'+($i18n.locale==='fr' ? 'fr' : 'en')].join(", ")}}
                                </p>
                            </template>
                            <template v-if="this.data_reimbursement['indication_'+($i18n.locale==='fr' ? 'fr' : 'en')]">
                            <div class="popup-indication">
                                <p><strong>{{ $t("Indication")}} : </strong><span v-html="this.data_reimbursement['indication_'+($i18n.locale==='fr' ? 'fr' : 'en')]"></span></p>
                            </div>
                            </template>
                        </template>
                        <template v-else>
                        
                            <ConclusionElement :item="this.data_reimbursement" style="text-align: left;"/>
                            
                            <template v-if="this.data_reimbursement.agency_model === 'Gba' && this.data_reimbursement.gba && this.data_reimbursement.gba.gba_addition_benefit && this.data_reimbursement.gba.gba_addition_benefit.length > 0">
                                <table class="table table-2column ">
                                    <tbody>
                                        <tr>
                                            <td style="width: 80%;">&nbsp;</td>
                                            <td style="width: 20%;"><strong>Added beneﬁt (AB)</strong></td>
                                        </tr>
                                        <tr v-for="(item_gba_addition, key) in this.data_reimbursement.gba.gba_addition_benefit" :key="key">
                                            <td>{{item_gba_addition.gba_addition_benefits_title}}</td>
                                            <td>{{$t('gba_added_benefit_id_'+item_gba_addition.gba_addition_benefits_gba_added_benefit_id)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>
                            <div class="fix_rationale_and_commentary" v-html="get_rationale_commentary(this.data_reimbursement)">
                                
                            </div>
                            <template v-if="this.data_reimbursement.agency_model === 'Transparence' && this.data_reimbursement.transparence && this.data_reimbursement.transparence.transparence_smr.length > 0">
                                <table class="table table-2column ">
                                    <tbody>
                                        <tr>
                                            <th style="width: 70%; border: 1px solid #e3dfdf !important; " >&nbsp;</th>
                                            <th style="width: 10%; border: 1px solid #e3dfdf !important; text-align:center;"><strong>{{$t('asmr.ASMR_obtenue_short')}}</strong></th>
                                            <th style="width: 10%; border: 1px solid #e3dfdf !important; text-align:center;"><strong>{{$t('asmr.ASMR_demande_short')}}</strong></th>
                                            <th style="width: 10%; border: 1px solid #e3dfdf !important; text-align:center;"><strong>{{$t('SMR')}}</strong></th>
                                        </tr>
                                        <tr v-for="(item, key) in this.data_reimbursement.transparence.transparence_smr" :key="key">
                                            <td>
                                                <div style="text-align: justify;">
                                                    {{ $i18n.locale !== 'fr' ? item.smr_title_en : item.smr_title}}
                                                </div>
                                            </td>
                                            <td style="text-align:center;">{{item.asmr_obtenue ? $t("transparence.asmr."+item.asmr_obtenue) : "" }}</td>
                                            <td style="text-align:center;">{{item.asmr_demande}}</td>
                                            <td style="text-align:center;">{{item.smr_type ? $t("transparence.smr."+item.smr_type) : ""}}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </template>

                        </template>

                    </template>
                    
                    <div class="main-overlay" v-if="load" style="margin-top:10px; margin-bottom:0px;">
                        <div class="loadding_circle" style="height:40px; width:40px;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>

<script>
import ConclusionElement from '@/components/elements/conclusion_element.vue'
import { get_rationale_commentary, icon_link, lowercase, color_rembursement, DDMMMYY, link_detail_url } from '@/utils';
export default {
    name : "OverviewModalHTAstatus",
    components : {
        ConclusionElement
    },
    data() { 
        return{
            decision_date : null,
            decision : null,
        }
    },
    props : {
        show : {
            type : Number,
            default : 0
        },
        item_data : {
            type : Object
        },
    },
    methods : {
        get_rationale_commentary,
        reimbursement_popup(item_data){
            this.$store.dispatch("detail/get_data_reimbursement", item_data)
        },
        set_drug_id(id){
            this.$store.dispatch("detail/set_drug_id", id)
        },
        set_agency(agency){
            this.$store.dispatch("detail/set_agency", agency)
        },
        icon_link,
        lowercase,
        color_rembursement,
        DDMMMYY,
        link_detail_url,
    },
    watch: {
        show(){
            if(this.item_data.drug_id){
                if(this.show === this.item_data.drug_id){
                    this.show_modal_hta = true
                    this.set_drug_id(this.item_data.drug_id)
                    this.set_agency(this.item_data.agency_model)
                    if(this.data_reimbursement == null || this.data_reimbursement == undefined){
                        this.reimbursement_popup(this.item_data) 
                    }
                }
            }else{
                if(this.show === this.item_data.id){
                    this.show_modal_hta = true
                    this.set_drug_id(this.item_data.drug_id)
                    this.set_agency(this.item_data.agency_model)
                    if(this.data_reimbursement == null || this.data_reimbursement == undefined){
                        this.reimbursement_popup(this.item_data) 
                    }
                }
            }
        },
    },
    computed : {
        link_agency() {
            switch (this.item_data.agency_model) {
                case "Transparence":
                    return "trs"
            }
            return this.lowercase(this.item_data.agency_model)
        },
        AgencyObj() {
            return this.item_data.agency_model;
        },
        translateRembursemet(){
            return this.$store.getters['result/translateRembursemet'];
        },
        data_reimbursement(){
            return this.$store.getters['detail/data_reimbursement'];
        },
        load(){
            return this.$store.getters['detail/load'];
        }
    },
    created(){
        switch (this.item_data.agency_type){
            case "Regulatory": 
                this.decision_date = this.DDMMMYY(this.item_data.decision_date_num)
                this.decision = this.item_data.decision ? "<span class=\"" + this.color_rembursement(this.item_data.decision) + "\" style=\"background-color: #fff;\">" + this.$t("conclusion." + this.item_data.decision) + "</span>" : ''
                break;
        }
    },
}
</script>

<style>
</style>